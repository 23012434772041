import Image from "next/image";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { Grid } from "@/components/layout";

type Props = {
  center?: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
  imageUrl?: string;
  showTags?: boolean;
  mbgn?: string;
  mobileImageHeight?: number;
  darkenImage?: boolean;
  darkenImageMobile?: boolean;
  verticallyCenter?: boolean;
};

const Wrapper = styled.div<{
  center: boolean;
  showTags?: boolean;
  mobileImageHeight?: number;
}>`
  padding: 29px 41px 40px 41px;
  position: relative;

  ${({ center }) =>
    center &&
    css`
      display: flex;
      align-items: center;
    `}
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 767px) {
    ${({ showTags, mobileImageHeight }) =>
      showTags
        ? css`
            min-height: inherit !important;
            height: 512px;
          `
        : css`
            min-height: ${mobileImageHeight || 512}px !important;
          `};
    padding: 29px 10px 40px 10px;
  }
`;

export default function Hero({
  children,
  center = false,
  imageUrl,
  mbgn,
  style,
  showTags,
  mobileImageHeight,
  darkenImage,
  darkenImageMobile,
  verticallyCenter,
}: Props) {
  const imageClassName = `left-0 top-0 z-[-1] object-cover`;
  return (
    <Wrapper
      center={center}
      style={style}
      showTags={showTags}
      mobileImageHeight={mobileImageHeight}
    >
      {imageUrl && (
        <Image
          src={imageUrl}
          alt=""
          fill
          className={`${imageClassName} ${darkenImage ? "brightness-50" : ""}`}
          priority
        />
      )}

      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          className={verticallyCenter ? "flex justify-center" : ""}
        >
          <div className="inner sm:mt-0">{children}</div>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
